<template>
  <div class="upload-box-container">
    <div
      class="drop-area"
      @drop="drop"
      @dragenter="dragEnter"
      @dragleave="dragLeave"
      @dragover="dragOverArea"
    >
      <div class="drop-area-content" v-bind:class="{ 'on-drop': onDropArea }">
        <span class="drop-text" v-if="onDropArea">
          Drop files here to upload...
        </span>
        <div class="upload-box" @click="isMobile && !isSpecialDeviceBrowser ? showTray() : initImgUploadFile()" ref="drop">
          <input
            ref="fileinput"
            type="file"
            id="files"
            name="files"
            style="display: none"
            multiple
            @change="uploadPhoto"
            v-if="!imageDeleting"
            accept="image/*"
          />
          <div class="upload-box-wrapper">
            <img src="@/assets/images/icons/ic_plus_square.svg" class="mb-4" />
            <p class="photo-text mb-0" v-if="isMobile">
              <span style="color: #1f8252">Add photos</span> from your device
            </p>
            <p class="photo-text" v-else>
              Drag and drop your images here, or
              <span style="color: #1f8252">browse</span>
            </p>
            <div class="fs-14 cover-description-text font-weight-thin">
              Supports: jpeg, png, tiff
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Bottom Tray -->
    <div
      v-if="isShowTray"
      class="tray-overlay"
      @click.self="closeTray"
    >
      <div class="tray" :class="{ 'active-tray': activeTray }">
        <div class="tray-header">
          <span>Add photos</span>
          <img src="@/assets/images/icons/ic_close_x_gray.svg" @click="closeTray">
        </div>

        <div class="tray-options">
          <button @click="openGuidedCamera" class="guided-camera-button right-arrow">
            <img src="@/assets/images/icons/ic_digital_camera_black.svg">
            <span>Guided Camera</span>
          </button>

          <button @click="initImgUploadFile" class="gallery-button right-arrow">
            <img src="@/assets/images/icons/ic_album_black.svg">
            <span>Gallery / Camera roll</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PhotoUploadBox",
  data() {
    return {
      onDropArea: false,
      isShowTray: false,
      activeTray: false,
    };
  },
  props: {
    isMobile: {
      type: Boolean,
      default: true,
    },
    imageDeleting: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 30,
    },
    errImgType: {
      type: Array,
      default: () => [],
    },
    handleDrop: {
      type: Function,
    },
    initImgUploadCamera: { type: Function },
    isSpecialDeviceBrowser: Boolean,
  },
  methods: {
    dragEnter() {
      this.onDropArea = true;
    },
    dragLeave: function(e) {
      if (!e.relatedTarget || !this.$el.contains(e.relatedTarget) || e.relatedTarget === this.$el) {
        this.onDropArea = false;
        this.doNothing(e);
        this.$refs.drop.style.opacity = 1;
      }
    },
    dragOverArea: function(e) {
      this.doNothing(e);
      this.onDropArea = true;
      this.$refs.drop.style.opacity = 0;
    },
    drop: function(e) {
      this.onDropArea = false;
      this.$refs.drop.style.opacity = 1;
      this.handleDrop(e);
    },
    doNothing(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    uploadPhoto(event) {
      this.$emit("uploadPhoto", event);
    },
    initImgUploadFile() {
      this.$refs.fileinput.click();
    },
    showTray() {
      this.isShowTray = !this.isShowTray;
      this.slideTray();
    },
    closeTray() {
      this.slideTray();
      this.isShowTray = !this.isShowTray;
    },
    slideTray() {
      setTimeout(() => {
        this.activeTray = !this.activeTray;
      }, 200);
    },
    openGuidedCamera() {
      if (this.isMobile) {
        this.closeTray();
        this.initImgUploadCamera();
      }
    },
  },
};
</script>
<style lang="scss">
.upload-box-container {
  width: 100%;
  .drop-area {
    .drop-area-content {
      position: relative;
      .drop-text {
        position: absolute;
        display: block;
        color: #276ef1;
        font-family: Avenir Next LT W05 Regular !important;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
        transform: translateX(-50%);
      }
      &.on-drop {
        background-color: #eff3fe !important;
        border: 2px dashed #276ef1 !important;
        border-radius: 15px;
        z-index: 9999 !important;
      }
      .upload-box {
        width: 100%;
        height: 175px;
        background-color: #eeeeee;
        border: 1px dashed #05b36d;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}

.tray-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 100001;
  .tray {
    position: relative;
    background-color: #FAFAFA;
    width: 100%;
    padding: 20px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    .tray-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      span {
        font-size: 24px;
        line-height: 28px;
        margin: 0;
        color: #000000;
      }
    }
    .tray-options {
      .guided-camera-button, .gallery-button {
        position: relative;
        width: 100%;
        padding: 15px;
        margin-bottom: 10px;
        border: none;
        background-color: #FFFFFF;
        font-size: 15px;
        display: flex;
        align-items: center;
        margin-top: 8px;
        font-weight: 400 !important;
        font-family: Avenir Next LT W05 Regular !important;
        color: #000000;
        &::before {
          content: '';
          border-bottom-style: solid;
          border-bottom-width: 2px;
          border-right-style: solid;
          border-right-width: 2px;
          border-color:#3C3C4399;
          position: absolute;
          top: 40%;
          height: 10px;
          width: 10px;
        }
        &.right-arrow {
          &::before {
            right: 30px;
            transform: rotate(315deg);
          }
        }
      }
      img {
        margin-right: 10px;
      }
    }
  }
  .active-tray {
    transform: translateY(0);
    opacity: 1;
  }
}
@media screen and(min-width: 768px) {
  .upload-box-container .drop-area .drop-area-content .upload-box {
    height: 250px;
  }
}
@media screen and(min-width: 991px) {
  .upload-box-container .drop-area .drop-area-content .upload-box {
    height: 286px;
  }
}
</style>
